import "../../core/src/desktop/dialogs/doc-info/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/doc-info/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE41T0YrbMBB8z1csB4X0QYfsuOlVR6GfUSil6CzZFshaIW0cpyX/XqzYqa32jr55197Zmdnx44+i7i8xEodfO4BejuxsFHUCqifux+epZ9zSOxznnpdKGdcKKLkfgU8taU3rWNS2ERBJBkqzMrTGMUIvoCgXQDmyTpu2IwG1tPW+4FwNHTAoK+7H99M3OOjQWDwLkCfC591197gwLRJTZaK38iKgsTqhzhQLzt9N5dRmygRdk0EnoEZ76t0GqExADTpi0fzUAgYZ9ozJpjFOs9Tv2DHRScV55nzkfINz+DehnEHA89RupRdwzGyc60XDzebViup+ndy4m2/l4tsdkUNZZSAfEsi8Y95IQbrYYOjF7dFK0l/3rEpwq9njejbdeyGSCo/R3FTKl4j2RHoz/XGm/ycMSxZeMCgd2AsSYS8gp/z0f9Yux10TCdpKMsOWyKeEt45qgrrn9c0wxD5ZXKPFkL0fSkZ6JBZ1jU7JcMmPUd3kLqYdZvs3uao43wbkuvumJEnmA3od6MJqtFb6qNXnBwon/fAdMmV3pxy6XHkc2jzwxUzjFUmmRreRtMYT3fSP3lHfgvDB9AvAl14rI8EH4ygNZgr+0gBw3V1/A8G1tSqnBAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPropertyButton = '_1cmysst9';
export var container = '_1cmysst0';
export var hiddenInput = '_1cmysst6';
export var rowNameContainer = '_1cmysst3';
export var scrollBar = '_1cmysst5';
export var tableBodyRoot = '_1cmysst8';
export var timeRow = '_1cmysst7';
export var titleContainer = '_1cmysst1';
export var titleStyle = '_1cmysst2';
export var viewport = '_1cmysst4';